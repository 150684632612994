import React from 'react'
import LogoImage from '../images/Logo.png'
import styled from "styled-components"
import { device } from '../components/Device'
import FacebookLogo from '../images/facebook.png'
import InstagramLogo from '../images/instagram.png'
import { useStaticQuery, graphql, Link } from "gatsby"

const FooterBox = styled.div`
  background: #3E3E3E;
  width: 100%;
`
const Container = styled.div`
    margin: auto;
    width: 1024px;
    padding-top: 100px;
    padding-bottom: 100px;

    @media ${device.laptop} {
        width: 768px;
    }

    @media ${device.tablet} {
        width: 425px;
    }

    @media ${device.mobileL} {
        width: 314px;
    }
`
const FooterSectionContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-start;
`
const FooterSection = styled.div`

`

const FooterSectionTitle = styled.h3`
/* H4 - Highlight */

font-family: Google Sans;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 17px;

/* Core / 0 */

color: #FFFFFF;
`

const GreyTextSpan = styled.span`
font-family: Google Sans;
font-style: normal;
font-weight: normal;
font-size: 10px;
line-height: 15px;
/* or 150% */


/* Core / 40 */

color: rgba(220, 220, 220, 0.6);
`

const CopyrightSpan = styled.span`
/* P2 - Small Text Body */

font-family: Google Sans;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 18px;
/* identical to box height, or 150% */
text-align: center;
width: 100%;
display: block;
margin-top: 70px;

/* Core / 40 */

color: rgba(220, 220, 220, 0.6);
`

const SocialNetworkContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    a {
      margin-top: 10px;
    }

    a:not(:first-child) {
      margin-left: 18px;
    }
`

const LinkContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    a {
      margin-top: 10px;
      font-family: Google Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      /* or 267% */


      /* Core / 40 */

      color: rgba(220, 220, 220, 0.6);
    }
`

const Footer = () => {
  const queryResponse = useStaticQuery(
    graphql`
    query {
          wpMenu(id: { eq: "dGVybToy" }) {
            menuItems {
              nodes {
                label
                path
                parentId
                childItems {
                  nodes {
                    label
                    path
                    id
                  }
                }
              }
            }
          }
        }     
  `
  )

 
  const menuItems = queryResponse.wpMenu.menuItems.nodes
  return <FooterBox>
    <Container>
      <FooterSectionContainer>
        <FooterSection>
          <img src={LogoImage} />
          <br />
          <GreyTextSpan>
            Centro Empresarial Ceilândia<br />
            CNM 1 Bloco I Lote 03, 4° Andar, sala 401/402 <br />
            Ceilândia - Brasília/DF<br /><br />
            (61) 3581-2795 | (61) 3581-6607 | Whatsapp Setor de Casamentos 61-8509-4174 | Whatsapp Setor de Nascimentos/Casamentos e Óbitos 61-8182-5119, Setor Títulos e Documentos e PJ 61-98515-0952  <br /><br />
            7rcrtd@gmail.com
          </GreyTextSpan>
        </FooterSection>

        <FooterSection>
        {menuItems.map(item => {
           if (item.childItems.nodes.length > 0) {
            return (<div key={item.label}>
               <FooterSectionTitle>{item.label}</FooterSectionTitle>
               <LinkContainer>
               {item.childItems.nodes.map(child => <Link key={child.path} to={`${child.path}`}>{child.label}</Link>)}
               </LinkContainer>
            </div>)
           }
        })}
        </FooterSection>

        <FooterSection>
        <FooterSectionTitle>Outros</FooterSectionTitle>
        {menuItems.map(item => {
           if (item.childItems.nodes.length == 0 && !item.parentId) {
            return (
               <LinkContainer key={item.path}>
                <Link key={item.path} to={`${item.path}`}>{item.label}</Link>
               </LinkContainer>
            )
           }
        })}
        </FooterSection>

        <FooterSection>
          <FooterSectionTitle>Nossas redes sociais</FooterSectionTitle>
          <SocialNetworkContainer>
            <a target="_blank" href="https://www.facebook.com/cartorioceilandia/"><img src={FacebookLogo} /></a>
            <a target="_blank" href="https://www.instagram.com/cartorioceilandia/"><img src={InstagramLogo} /></a>
          </SocialNetworkContainer>
        </FooterSection>
      </FooterSectionContainer>
      <CopyrightSpan>© 2021 7º Ofício Registro Civil Títulos e Documentos e Pessoas Jurídicas. Todos os direitos reservados.</CopyrightSpan>
    </Container>
  </FooterBox>
}


export default Footer
